import type { DocumentType, DurableJobExpandOpts, IDurableJob } from '@condo/domain';
import type { ICreateGptParseRequestParams, IFetchDurableJobsParams } from '@condo/domain/src/__unsorted/requests';
import { getClient } from './api-client';

export const fetchDurableJobs = async (params?: IFetchDurableJobsParams): Promise<{ durableJobs: IDurableJob[]; total: number }> =>
    getClient('basecamp')
        .get('/durable-jobs', { params })
        .then(response => response.data);

export const fetchDurableJob = async (id: string): Promise<IDurableJob> =>
    getClient('basecamp')
        .get(`/durable-jobs/${id}`)
        .then(response => response.data.durableJob);

export const fetchUnseenDurableJobs = async (expand?: DurableJobExpandOpts): Promise<{ durableJobs: IDurableJob[]; total: number }> =>
    getClient('basecamp')
        .get('/durable-jobs/unseen', { params: { expand } })
        .then(response => response.data);

export const fetchDurableJobsList = async (): Promise<{ name: string; cron: string | null; lastSuccessfulRun: Partial<IDurableJob> | null }[]> =>
    getClient('basecamp')
        .get('/durable-jobs/list')
        .then(response => response.data.durableJobs);

export const markDurableJobsSeen = async (ids: string[]): Promise<IDurableJob[]> =>
    getClient('basecamp')
        .put('/durable-jobs/seen', { ids })
        .then(response => response.data.durableJobs);

export const cancelDurableJob = async (jobUuid: string): Promise<void> => getClient('basecamp').delete(`/durable-jobs/${jobUuid}`);
export const rerunDurableJob = async (jobUuid: string): Promise<void> => getClient('basecamp').post(`/durable-jobs/v0/${jobUuid}/rerun`);

export const createGptParseRequests = async (data: ICreateGptParseRequestParams): Promise<void> =>
    getClient('basecamp').post('/documents/gpt-parse-request', data);

export const createMfhAttributeGptParseRequest = async (mfhId: number): Promise<void> =>
    getClient('basecamp').post(`/mfh/${mfhId}/attribute-gpt-parse-request`);

export const scheduleDurableJob = async (params: {
    name: string;
    params?: Record<string, any>;
    meta?: Record<string, any>;
    opts?: { runAfter?: number; priority?: number };
}): Promise<IDurableJob> =>
    getClient('basecamp')
        .post('/durable-jobs', params)
        .then(response => response.data.durableJob);

export const scheduleDocumentDurableJob = async (params: {
    template: DocumentType;
    output?: {
        type?: string;
        targetFolderId?: string;
        targetFolderName?: string;
        convertToPdf?: boolean;
        exportXlsToBucket?: boolean;
        targetFolderIsPersonalDrive?: boolean;
    };
    params?: Record<string, any>;
}): Promise<IDurableJob> =>
    getClient('basecamp')
        .post('/documents/durable-jobs', params)
        .then(response => response.data.durableJob);
